import React, { createContext, useContext, useEffect, useState } from "react";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
  sendEmailVerification,
  updateEmail,
  reload,
  deleteUser,
} from "firebase/auth";
import { captureException } from "@sentry/minimal";
import { useFirestore } from "./useFirestore";
import { useSnackbar } from "./useSnackbar";

export const AuthContext = createContext({
  isBusy: false,
  authToken: null,
  setAuthToken: () => {},
});

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(
    typeof window === "object" ? window.localStorage.getItem("authToken") : null
  );
  const [isBusy, setIsBusy] = useState(false);

  const onSetAuthToken = (token) => {
    setAuthToken(token);
    if (token) {
      localStorage.setItem("authToken", token);
    } else {
      localStorage.removeItem("authToken", token);
    }
  };

  useEffect(() => {
    if (typeof window === "object" && !authToken) {
      const token = window.localStorage.getItem("authToken");
      if (token) {
        onSetAuthToken(token);
      }
    }
  }, [authToken]);

  return (
    <AuthContext.Provider
      value={{
        isBusy,
        setIsBusy,
        authToken,
        setAuthToken: onSetAuthToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const { authToken, setAuthToken, isBusy, setIsBusy } =
    useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const { setDoc } = useFirestore();
  const isAuthenticated = !!authToken;

  const initUserInFirestore = (email) => {
    return setDoc("users", email, {}, { merge: true });
  };

  const handleAuthError = (error) => {
    if (error.code === "auth/wrong-password") {
      enqueueSnackbar("Nepareiza parole!", { variant: "error" });
    } else if (error.code === "auth/too-many-requests") {
      enqueueSnackbar("Pārāk daudz mēģinājumi! Lūdzu, mēģini vēlāk.", {
        variant: "error",
      });
    } else if (error.code === "auth/account-exists-with-different-credential") {
      enqueueSnackbar("Šim kontam jālieto cita pieslēgšanās metode!", {
        variant: "error",
      });
    } else if (error.code === "auth/popup-closed-by-user") {
      enqueueSnackbar("Pieslēgšanās pārtraukta.", { variant: "warning" });
    } else {
      captureException(error);
      enqueueSnackbar("Neizdevās pieslēgties! Lūdzu, mēģini vēlāk.", {
        variant: "error",
      });
    }
  };

  const doGoogleAuth = async () => {
    setIsBusy(true);
    try {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (credential) {
        const {
          user: { email },
        } = result;
        const { accessToken } = credential;
        setAuthToken(accessToken);
        await initUserInFirestore(email);
      }
      setIsBusy(false);
    } catch (error) {
      console.error(error);
      setIsBusy(false);
      handleAuthError(error);
    }
  };

  const doFacebookAuth = async () => {
    setIsBusy(true);
    try {
      const provider = new FacebookAuthProvider();
      provider.setCustomParameters({
        display: "popup",
      });
      const auth = getAuth();
      const result = await signInWithPopup(auth, provider);
      const credential = FacebookAuthProvider.credentialFromResult(result);
      if (credential) {
        const {
          user
        } = result;
        const { accessToken } = credential;
        setAuthToken(accessToken);
        if (user.email) {
          await initUserInFirestore(user.email);
        }
      }
      setIsBusy(false);
    } catch (error) {
      console.error(error);
      setIsBusy(false);
      handleAuthError(error);
    }
  };

  const doEmailPasswordAuth = async (email, password) => {
    setIsBusy(true);
    try {
      try {
        const auth = getAuth();
        const { user } = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );

        if (user) {
          const { refreshToken } = user;
          setAuthToken(refreshToken);
          await initUserInFirestore(email);
        }
        setIsBusy(false);
      } catch (error) {
        if (error.code === "auth/email-already-in-use") {
          const auth = getAuth();
          const { user } = await signInWithEmailAndPassword(
            auth,
            email,
            password
          );

          if (user) {
            const { refreshToken } = user;
            setAuthToken(refreshToken);
            await initUserInFirestore(email);
          }
          setIsBusy(false);
        } else {
          throw error;
        }
      }
    } catch (error) {
      console.error(error);
      setIsBusy(false);
      handleAuthError(error);
    }
  };

  const updateAuthUser = (displayName) => {
    return updateProfile(getAuth().currentUser, { displayName });
  };

  const updateAuthEmail = (email) => {
    return updateEmail(getAuth().currentUser, email);
  };

  const authSendPasswordResetEmail = async (email) => {
    setIsBusy(true);
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email, {
        url: "https://jfs.lu.lv/ienakt",
      });
      enqueueSnackbar(
        "Uz norādīto e-pastu nosūtīta paroles atiestatīšanas saite.",
        { variant: "success" }
      );
      setIsBusy(false);
    } catch (error) {
      console.error(error);
      setIsBusy(false);
      enqueueSnackbar("Atiestatīšana neizdevās! Lūdzu, mēģini vēlāk.", {
        variant: "error",
      });
    }
  };

  const authSendEmailVerification = async () => {
    try {
      await sendEmailVerification(getAuth().currentUser, {
        url: "https://jfs.lu.lv/profils",
      });
      enqueueSnackbar(
        "Esam nosūtījuši uz tavu e-pastu ziņu. Spied uz e-pastā esošās saites, lai pabeigtu verifikāciju.",
        {
          variant: "success",
        }
      );
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        "Neizdevās nosūtīt verifikācijas e-pastu! Lūdzu, mēģini vēlāk.",
        {
          variant: "error",
        }
      );
    }
  };

  const deleteAuthUser = () => {
    return deleteUser(getAuth().currentUser);
  };

  const logOut = () => {
    getAuth().signOut();
    setAuthToken(null);
  };

  return {
    isBusy,
    authToken,
    isAuthenticated,
    doGoogleAuth,
    doFacebookAuth,
    doEmailPasswordAuth,
    logOut,
    getAuth,
    updateAuthUser,
    updateAuthEmail,
    reloadAuthUser: reload,
    sendPasswordResetEmail: authSendPasswordResetEmail,
    sendEmailVerification: authSendEmailVerification,
    deleteAuthUser,
  };
};
