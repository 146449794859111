import { useSnackbar as useNotistackSnackbar } from "notistack";

const noop = () => {};

export const useSnackbar = () => {
  const notistackSnackbar = useNotistackSnackbar();
  return {
    enqueueSnackbar: notistackSnackbar?.enqueueSnackbar || noop,
    closeSnackbar: notistackSnackbar?.closeSnackbar || noop,
  };
};
