// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-apmeklejums-js": () => import("./../../../src/pages/apmeklejums.js" /* webpackChunkName: "component---src-pages-apmeklejums-js" */),
  "component---src-pages-ienakt-js": () => import("./../../../src/pages/ienakt.js" /* webpackChunkName: "component---src-pages-ienakt-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-profils-js": () => import("./../../../src/pages/profils.js" /* webpackChunkName: "component---src-pages-profils-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-school-cup-results-js": () => import("./../../../src/templates/SchoolCupResults.js" /* webpackChunkName: "component---src-templates-school-cup-results-js" */)
}

