import {
  doc,
  setDoc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  collection,
  orderBy,
  deleteDoc,
  where,
  onSnapshot,
  runTransaction,
  writeBatch,
  increment,
  collectionGroup,
} from "firebase/firestore";
import React, { useContext, useMemo, createContext } from "react";

export const FirestoreContext = createContext({
  db: null,
});

export const FirestoreProvider = ({ children }) => {
  const db = useMemo(() => getFirestore(), []);

  return (
    <FirestoreContext.Provider
      value={{
        db,
      }}
    >
      {children}
    </FirestoreContext.Provider>
  );
};

export const useFirestore = () => {
  const { db } = useContext(FirestoreContext);

  const setFirestoreDoc = (collection, key, data, opts) => {
    return setDoc(doc(db, collection, key), data, opts);
  };

  const getFirestoreDoc = (collection, key) => {
    return getDoc(doc(db, collection, key));
  };

  const waitForDocUpdate = (collection, key, onChange) => {
    return new Promise((resolve, reject) => {
      const unsub = onSnapshot(
        doc(db, collection, key),
        (snapshot) => {
          onChange(snapshot, () => {
            unsub();
            resolve();
          });
        },
        (error) => {
          unsub();
          reject(error);
        }
      );
      setTimeout(() => {
        unsub();
        reject(new Error("wait timeout"));
      }, 600000);
    });
  };

  const firestoreDoc = (collection, key) => {
    return doc(db, collection, key);
  };

  const deleteFirestoreDoc = (collection, key) => {
    return deleteDoc(doc(db, collection, key));
  };

  const getFirestoreDocs = (collectionName, ...queryArguments) => {
    return getDocs(query(collection(db, collectionName), ...queryArguments));
  };

  const runFirestoreTransaction = (transaction) => {
    return runTransaction(db, transaction);
  };

  const writeFirestoreBatch = () => {
    return writeBatch(db);
  };

  const getCollectionGroup = (collectionName, ...queryArguments) => {
    return getDocs(
      query(collectionGroup(db, collectionName), ...queryArguments)
    );
  };

  return {
    db,
    orderBy,
    where,
    doc: firestoreDoc,
    setDoc: setFirestoreDoc,
    getDoc: getFirestoreDoc,
    getDocs: getFirestoreDocs,
    deleteDoc: deleteFirestoreDoc,
    waitForDocUpdate: waitForDocUpdate,
    runTransaction: runFirestoreTransaction,
    writeBatch: writeFirestoreBatch,
    increment: increment,
    getCollectionGroup,
  };
};
