import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { captureException } from "@sentry/minimal";
import { useAuth } from "./useAuth";
import { useFirestore } from "./useFirestore";
import { useSnackbar } from "./useSnackbar";

export const UserContext = createContext({
  user: null,
});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const [isReady, setIsReady] = useState(false);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        isBusy,
        setIsBusy,
        isReady,
        setIsReady,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const { user, setUser, isBusy, setIsBusy, isReady, setIsReady } =
    useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const {
    isAuthenticated,
    getAuth,
    updateAuthUser,
    updateAuthEmail,
    reloadAuthUser,
    deleteAuthUser,
    logOut,
  } = useAuth();
  const { getDoc, setDoc, deleteDoc } = useFirestore();

  const isUserAndProfileReady = isAuthenticated && !!user && isReady;
  const hasCompletedProfile =
    isUserAndProfileReady &&
    user.displayName &&
    user.email &&
    user.profile.school &&
    user.profile.schoolYear;

  const loadProfile = useCallback(
    async (email) => {
      setIsReady(false);
      const profile = email ? (await getDoc("users", email)).data() || {} : {};
      setUser((user) => ({ ...user, profile }));
      setIsReady(true);
    },
    [getDoc, setUser, setIsReady]
  );

  const setAdditionalAttributes = useCallback(
    async (auth) => {
      const canChangeEmail = !auth.currentUser.providerData.some(
        ({ providerId }) => new Set(["google.com"]).has(providerId)
      );
      const isEmailVerified = auth.currentUser.emailVerified;
      const isAdmin = !!(await auth.currentUser.getIdTokenResult())?.claims
        ?.admin;
      setUser((user) => ({
        ...user,
        canChangeEmail,
        isEmailVerified,
        isAdmin,
      }));
    },
    [setUser]
  );

  const initializeUser = useCallback(
    (authUser) => {
      const { email, displayName } = authUser;
      setUser((user) => ({ ...user, ...{ email, displayName } }));
      loadProfile(email);
      setAdditionalAttributes(getAuth());
    },
    [setUser, loadProfile, setAdditionalAttributes, getAuth]
  );

  const reloadUser = async () => {
    await reloadAuthUser(getAuth().currentUser);
    initializeUser(getAuth().currentUser);
  };

  const updateProfile = async (email, profile) => {
    await setDoc("users", email, profile, { merge: true });
    setUser((user) => ({ ...user, profile }));
  };

  const deleteProfile = async (email) => {
    await deleteDoc("users", email);
  };

  const handleUpdateUserError = (error) => {
    switch (error.code) {
      case "auth/email-already-in-use": {
        enqueueSnackbar("Lietotājs ar šādu e-pastu jau eksistē!", {
          variant: "error",
        });
        return;
      }
      case "auth/requires-recent-login": {
        enqueueSnackbar(
          "Lai veiktu darbības ar savu kontu, tev jābūt svaigi pieslēgušamies savam kontam. Lūdzu izej un ienāc savā kontā no jauna.",
          {
            variant: "error",
          }
        );
        return;
      }
      default: {
        captureException(error)
        enqueueSnackbar("Kaut kas nogāja greizi! Lūdzu mēģini vēlāk.", {
          variant: "error",
        });
        return;
      }
    }
  };

  const updateUser = async ({ email, displayName, profile }) => {
    setIsBusy(true);
    const oldEmail = user.email;
    try {
      if (!oldEmail) {
        await updateAuthEmail(email);
      } else if (email !== oldEmail) {
        await deleteProfile(oldEmail);
        try {
          await updateAuthEmail(email);
        } catch (error) {
          await updateProfile(oldEmail, profile);
          throw error;
        }
      }
      await Promise.all([
        updateProfile(email, profile),
        updateAuthUser(displayName),
      ]);
      setUser((user) => ({ ...user, displayName }));
      reloadUser();
      enqueueSnackbar("Izmaiņas saglabātas!", { variant: "success" });
    } catch (error) {
      console.error(error);
      handleUpdateUserError(error);
    }
    setIsBusy(false);
  };

  const deleteUser = async () => {
    setIsBusy(true);
    try {
      await deleteProfile(user.email);
      try {
        await deleteAuthUser();
      } catch (e) {
        await updateProfile(user.email, user.profile);
        throw e;
      }
      logOut();
      window.location.reload();
    } catch (error) {
      console.error(error);
      handleUpdateUserError(error);
    }
    setIsBusy(false);
  };

  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged((authUser) => {
      if (!user && !!authUser) {
        initializeUser(authUser);
      }
    });
    return () => unsubscribe();
  }, [
    setUser,
    getAuth,
    loadProfile,
    setAdditionalAttributes,
    user,
    initializeUser,
  ]);

  useEffect(() => {
    if (!isAuthenticated) {
      setUser(null);
    }
  }, [isAuthenticated, setUser]);

  return {
    user: { ...user, hasCompletedProfile },
    isReady,
    isBusy,
    updateUser,
    updateProfile,
    setUser,
    deleteUser,
  };
};
