// Highlighting for code blocks
import "prismjs/themes/prism.css";
import "./src/styles.css";
import React from "react";
import { StylesProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";

import { FirebaseProvider } from "./src/hooks/useFirebase";
import { FirestoreProvider } from "./src/hooks/useFirestore";
import { AuthProvider } from "./src/hooks/useAuth";
import { UserProvider } from "./src/hooks/useUser";

export const wrapRootElement = ({ element }) => (
  <StylesProvider injectFirst>
    <SnackbarProvider>
      <FirebaseProvider>
        <FirestoreProvider>
          <AuthProvider>
            <UserProvider>{element}</UserProvider>
          </AuthProvider>
        </FirestoreProvider>
      </FirebaseProvider>
    </SnackbarProvider>
  </StylesProvider>
);
